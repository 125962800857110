/* eslint-disable */
import axios from 'axios'
import setup from '@/plugins/setup-auth-api'

export default function plugin(Vue, pluginConfig) {
  if (plugin.installed) { return }
  Vue.$client = setup(axios.create({
    baseURL: process.env.VUE_APP_CLIENT_API,
  }))

  Object.defineProperties(Vue.prototype, {
    $client: {
      get () {
        return Vue.$client
      }
    }
  })
}
