import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

Vue.use(Router);

function guardRoute(to, from, next) {
  const user = store.getters['Auth/user']
  if (!user.id) {
    return store.dispatch('Auth/authProcess', {}, {
      root: true,
    })
    .then((response) => {
      const userFromResponse = response
      if (userFromResponse.id) {
        console.log(to)
        if (to.query.header === 'false') {
          store.dispatch('Shortener/setShowHeader', false)
        } else {
          store.dispatch('Shortener/setShowHeader', true)
        }
        next({ name: 'ShortenerClient', params: { client: store.getters['Auth/clientId'] }, query: { ...to.query }})
      } else {
        throw response
      }
    })
    .catch(() => {
      window.location.href = process.env.VUE_APP_SHORTENER_INTERN;
    })
  } else {
    next()
  }
}

import MainView from '@/views/MainView.vue'
import Client from '@/components/client/Index.vue'
import ClientEdit from '@/components/client/EditPopup.vue'
import AccessForbidden from '@/components/AccessForbidden.vue'
const scrollBehavior = () => ({ x: 0, y: 0 });

const router = new Router({
  scrollBehavior,
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'client',
      component: MainView,
      redirect: { name: 'ShortenerClient' },
      beforeEnter: guardRoute,
      children: [
        {
          path: '/:client',
          name: 'ShortenerClient',
          meta: {
            showModal: false,
          },
          components: {
            content: Client,
          },
        },
        {
          path: '/:client/:configId/create',
          name: 'ShortenedLinkNew',
          meta: {
            showModal: true,
          },
          props: {
            modal: { creation: true }
          },
          components: {
            content: Client,
            modal: ClientEdit,
          }
        },
      ]
    },
    {
      path: '/forbidden',
      name: 'AccessForbidden',
      component: AccessForbidden
    }
  ],
});

export default router;
