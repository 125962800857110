// to add proxy
// import router from '../../../router';

const mapStateToRoute = (store) => {
  store.subscribe((mutation) => {
    // const { query } = router.history.current;
    // let ids = [];
    switch (mutation.type) {
      // case 'Segment/SET_SEGMENTS':
      //   router.push({ query: { ...query, ...mutation.payload.params } });
      //   break;
      default:
        break;
    }
  });
};
export default mapStateToRoute;
