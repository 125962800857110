import Vue from 'vue';
// import VueCookie from 'vue-cookie';

import stateToGetters from '@/store/helpers/stateToGetters';
import stateToCatchErrorAPI from '@/store/helpers/stateToCatchErrorAPI';
import stateToMutations from '@/store/helpers/stateToMutations';

// import store from '@/store';

// initial state
const authState = {
  clientId: '',
  user: {},
  userLoading: false,
  client: '',
};

const catchErrorAPI = stateToCatchErrorAPI(authState);
// actions
const actions = {
  set_client_id({
    commit,
  }, clientId) {
    commit('SET_CLIENT_ID', clientId)
  },
  get_client({
    commit,
  }) {
    commit('UNSET_CLIENT');
    return Vue.$client.get('/user/client').then(({
      data,
    }) => {
      commit('SET_CLIENT', data.data);
    })
    .catch(catchErrorAPI({ commit }));
  },
  get_user({
    commit,
    dispatch
  }) {
    commit('UNSET_USER');
    commit('SET_USER_LOADING');

    return Vue.$auth.get('/user')
    .then((response) => {
      const data = response.data;
      if (data.code === 200) {
        commit('SET_USER', data.data);
        dispatch('get_client')
      } else {
        throw response
      }
      commit('UNSET_USER_LOADING');
    })
  },
  authProcess({
    dispatch,
    state
  }) {
    return dispatch('get_user')
      .then(() => {
        if (state.user) {
          return state.user
        }
      })
      .catch((error) => error)
  },

};

export default {
  namespaced: true,
  state: authState,
  getters: stateToGetters(authState),
  actions,
  mutations: stateToMutations(authState),
};
