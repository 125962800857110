<template>
  <ul class="list">
    <li class="list-item">
      <globe-icon class="list-item__icon" />
      <span class="list-item__description">Domain:</span>
      <span
        v-if="selectedConfig && selectedConfig.fqdn"
        class="list-item__value"
      >{{ selectedConfig.fqdn }}
      </span>
      <span v-else>-</span>
    </li>
    <li class="list-item">
      <tag-icon class="list-item__icon" />
      <span class="list-item__description">Id campaign:</span>
      <span
        v-if="selectedConfig && selectedConfig.idc && campaign && campaign.desc"
        class="list-item__value"
      >{{ selectedConfig.idc }} - {{ campaign.desc }}
      </span>
      <span v-else>-</span>
    </li>
    <li class="list-item">
      <target-icon class="list-item__icon" />
      <span class="list-item__description">Channel:</span>
      <span
        v-if="campaign && campaign.cat"
        class="list-item__value"
      >{{ campaign.cat }}
      </span>
      <span v-else>-</span>
    </li>
    <li class="list-item">
      <clock-icon class="list-item__icon" />
      <span class="list-item__description">Time to live:</span>
      <span
        v-if="selectedConfig && selectedConfig.ttl"
        class="list-item__value"
      >{{ selectedConfig.ttl | secondsToDays }} days
      </span>
      <span v-else>
        -
      </span>
    </li>
  </ul>
</template>

<script>
import {
  GlobeIcon, TargetIcon, TagIcon, ClockIcon
} from 'vue-feather-icons';

export default {
  name: 'ConfigInfo',
  components: {
    GlobeIcon,
    TargetIcon,
    TagIcon,
    ClockIcon
  },
  filters: {
    secondsToDays(value) {
      return Math.floor(Number(value) / (3600 * 24));
    }
  },
  props: {
    selectedConfig: {
      type: Object,
      default: null
    },
    campaign: {
      type: Object,
      default: null
    }
  },
};
</script>

<style lang="scss" scoped>
.list {
  font-size: 14px;
  list-style: none;
}

.list-item {
  padding: 5px;
  display: flex;
  align-items: center;
}

.list-item__icon {
  margin-right: 6px;
  color: grey;
}

.list-item__description {
  display: inline-block;
  width: 110px;
  color: grey;
}

.list-item__value {
  font-weight: 4er00;
}


</style>
