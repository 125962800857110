import Vue from 'vue';

import stateToGetters from '@/store/helpers/stateToGetters';
import stateToCatchErrorAPI from '@/store/helpers/stateToCatchErrorAPI';
import stateToMutations from '@/store/helpers/stateToMutations';

const clientState = {
  currentClient: null,
};

const catchErrorAPI = stateToCatchErrorAPI(clientState);

const actions = {
  resetClientState({ commit }) {
    commit('SET_CURRENT_CLIENT', null);
  },
  getClientUser({ commit }, params) {
    return Vue.$client.get('/user/client', params)
      .then(({ data }) => {
        commit('SET_CURRENT_CLIENT', data.data);
      })
      .catch(catchErrorAPI({ commit }));
  }
};

export default {
  namespaced: true,
  state: clientState,
  getters: stateToGetters(clientState),
  actions,
  mutations: stateToMutations(clientState),
};
