<template>
  <div
    class="header"
  >
    <div style="margin-left: 4.16667%; height: 100%; display: flex; align-items: center;">
      <h2 style="color: white">
        Shortener
      </h2>
    </div>
    <div style="margin-right: 4.16667%; display: flex; height: 100%;">
      <img
        v-if="currentClient"
        width="60"
        :src="currentClient.logo_url"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentClient: 'Client/currentClient',
    }),
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  background-color: #008BC5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
