<template>
  <div class="home">
    <el-row
      type="flex"
      justify="center"
      style="margin-bottom: 150px;"
    >
      <el-col :span="24">
        <div
          class="font-main-title"
          style="height: 75px;"
        >
          <i class="el-icon-s-operation" /><span style="padding-left: 4px;">Configuration</span>
        </div>
        <el-select
          v-if="configs && configs.length"
          value-key="description"
          :value="selectedConfig"
          style="width: 250px;"
          @change="setSelectedConfig"
        >
          <el-option
            v-for="config in configs"
            :key="config._id"
            :label="config.description"
            :value="config"
          />
        </el-select>
        <ConfigInfo
          v-if="configs && configs.length && selectedConfig"
          :selected-config="selectedConfig"
          :campaign="campaign"
        />
        <el-row
          v-if="configs && configs.length && selectedConfig"
          type="flex"
          justify="space-between"
          style="margin-bottom:20px;"
        >
          <el-col
            :span="10"
            :md="8"
            :lg="6"
          >
            <el-input
              v-model="searchShortenedUrl"
              prefix-icon="el-icon-search"
              style="width: 100%;"
              placeholder="Search exact shortened URL"
              @input="search"
            />
          </el-col>
          <el-col
            :offset="4"
            :span="10"
          >
            <el-button
              v-if="user.permissions.includes('datalively:role:shortener:w')"
              style="float:right"
              type="primary"
              @click="addModel"
            >
              Create a shortened link
            </el-button>
          </el-col>
        </el-row>
        <el-table
          v-if="configs && configs.length && selectedConfig"
          v-loading="loadingCampaigns"
          :data="campaigns"
          @sort-change="sort"
        >
          <el-table-column
            min-width="140"
            prop="creation_date"
            label="Creation Date"
            sortable
          >
            <template slot-scope="scope">
              {{ scope.row.creation_date | formatDate }}
            </template>
          </el-table-column>
          <el-table-column
            min-width="100"
            prop="name"
            label="Description"
          />
          <el-table-column
            min-width="130"
            prop="short"
            label="Shortened URL"
          />
          <el-table-column
            min-width="130"
            prop="url"
            label="Destination URL"
          />
          <el-table-column
            min-width="180"
            sortable
            prop="expiration_date"
            label="Link expiration date"
          >
            <template slot-scope="scope">
              {{ scope.row.expiration_date | formatDate }}
            </template>
          </el-table-column>
          <el-table-column
            width="90"
            label="Copy link"
          >
            <template slot-scope="scope">
              <el-tooltip
                v-if="clipboardLinkHash && clipboardLinkHash[scope.row._id]"
                :disabled="clipboardLinkHash[scope.row._id].disabled"
                content="Copy clipboard !"
                placement="top"
              >
                <el-button @click="copy(scope.row)">
                  <link-icon size="14" />
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="user.permissions.includes('datalively:role:shortener:w')"
            width="90"
            label="Delete"
          >
            <template slot-scope="scope">
              <el-popover
                v-if="deleteShort && deleteShort[scope.row._id]"
                v-model="deleteShort[scope.row._id].visible"
                placement="top"
              >
                <p>Are you sure to delete this?</p>
                <div style="text-align: right; margin: 0">
                  <el-button
                    size="mini"
                    type="text"
                    @click="deleteShort[scope.row._id].visible = false"
                  >
                    cancel
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="deleteShortenedLink(scope.row)"
                  >
                    confirm
                  </el-button>
                </div>
                <el-button
                  slot="reference"
                  type="danger"
                  icon="el-icon-delete"
                />
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="campaigns"
          :page-size="25"
          :current-page.sync="currentPage"
          :hide-on-single-page="true"
          style="text-align: center; margin-top: 8px;"
          background
          layout="prev, pager, next"
          :total="count"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LinkIcon } from 'vue-feather-icons';
import ConfigInfo from '@/components/client/ConfigInfo.vue';

export default {
  name: 'ClientList',
  components: {
    LinkIcon,
    ConfigInfo,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return value.split('T')[0] || value;
      }
      return '';
    }
  },
  data() {
    return {
      currentPage: 0,
      timeout: null,
      open: false,
      searchShortenedUrl: '',
      itemPerPage: 5,
      campaignFilter: {
        skip: 0,
        limit: 25,
        config_ids: null,
        sort: '-creation_date'
      },
      deleteShort: null,
      clipboardLinkHash: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'Auth/user',
      loadingCampaigns: 'Shortener/loadingCampaigns',
      currentClient: 'Client/currentClient',
      configs: 'Shortener/configs',
      campaign: 'Campaign/campaign',
      campaigns: 'Shortener/campaigns',
      count: 'Shortener/countCampaigns',
      selectedConfig: 'Shortener/selectedConfig'
    }),
    start() {
      if (this.currentPage > 0) {
        return (this.currentPage - 1) * this.itemPerPage;
      }
      return 0;
    },
  },
  watch: {
    campaigns(campaigns) {
      if (campaigns) {
        this.deleteShort = campaigns.reduce((previous, current) => {
          const toAdd = previous;
          toAdd[current._id] = { visible: false };
          return toAdd;
        }, {});
        this.clipboardLinkHash = campaigns.reduce((previous, current) => {
          const toAdd = previous;
          toAdd[current._id] = { disabled: true };
          return toAdd;
        }, {});
      }
    },
    currentPage: {
      handler(page) {
        if (page >= 1) {
          this.campaignFilter.skip = this.start;
          this.getCampaigns({ ...this.campaignFilter });
          this.$router.replace({
            path: this.$route.path,
            query: { page }
          });
        } else {
          this.$router.replace({
            path: this.$route.path,
            query: null
          }).catch(() => {});
        }
      },
      immediate: true
    },
    selectedConfig: {
      handler(config) {
        if (config) {
          this.getUserCampaignById(config.idc);
          this.campaignFilter.config_ids = config._id;
          this.getCampaigns({ ...this.campaignFilter });
        } else {
          this.currentPage = 0;
          this.resetFilter();
          this.resetCampaign();
        }
      },
      immediate: true
    },
  },
  async mounted() {
    try {
      await this.getConfigsUser();
      if (this.configs && this.configs.length) {
        this.setSelectedConfig(this.configs[0]);
      }
    } catch (e) {
      if (e.status === 403) {
        this.resetClientState();
        this.$router.push({ name: 'AccessForbidden' });
      }
    }
  },
  beforeDestroy() {
    this.setSelectedConfig(null);
  },
  methods: {
    ...mapActions({
      resetClientState: 'Client/resetClientState',
      getClientUser: 'Client/getClientUser',
      getConfigsUser: 'Shortener/getConfigsUser',
      getUserCampaignById: 'Campaign/getUserCampaignById',
      getCampaigns: 'Shortener/getCampaigns',
      resetCampaign: 'Shortener/resetCampaign',
      deleteCampaign: 'Shortener/deleteCampaign',
      setSelectedConfig: 'Shortener/setSelectedConfig'
    }),
    async sort(sort) {
      try {
        if (sort.prop === 'creation_date') {
          this.campaignFilter.sort = sort.order === 'ascending' ? 'creation_date' : '-creation_date';
        } else if (sort.prop === 'expiration_date') {
          if (sort.order === 'ascending') this.campaignFilter.sort = 'expiration_date';
          else if (sort.order === 'descending') this.campaignFilter.sort = '-expiration_date';
          else this.campaignFilter.sort = '-creation_date';
        }
        await this.getCampaigns({ ...this.campaignFilter });
      } catch (err) {
        this.$message.error('Error to get campaigns');
      }
    },
    search(searchValue) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.campaignFilter.shorts = searchValue;
        this.getCampaigns({ ...this.campaignFilter });
      }, 300);
    },
    resetFilter() {
      this.campaignFilter = {
        skip: 0,
        limit: 25,
        config_ids: null,
        sort: '-creation_date'
      };
    },
    copy(campaign) {
      const link = `https://${campaign.domain}/${campaign.short}`;
      this.$clipboard(link);
      this.$message({
        type: 'success',
        message: `${link} copied to clipboard`
      });
    },
    async deleteShortenedLink({ _id }) {
      try {
        await this.deleteCampaign(_id);
        this.deleteShort[_id].visible = false;
        this.$message({
          type: 'success',
          message: 'Shortened link deleted successfully'
        });
        await this.getCampaigns({ ...this.campaignFilter });
      } catch (err) {
        this.$message.error('An error happend on delete');
      }
    },
    addModel() {
      if (this.selectedConfig) {
        this.$router.push({
          name: 'ShortenedLinkNew',
          params: {
            client: this.$route.params.client,
            configId: this.selectedConfig._id,
          },
          query: { ...this.campaignFilter }
        }).catch(() => {});
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'Cannot create without choosing a config'
        });
      }
    }
  }
};
</script>

<style>
.el-select-dropdown__item.selected {
  color: #4a4a4a;
}

.el-select-dropdown__item {
  color: #606266;
}
</style>
