import Vue from 'vue';
import Vuex from 'vuex';

import Auth from './modules/AuthStore';
import Client from './modules/ClientStore';
import Shortener from './modules/ShortenerStore';
import Campaign from './modules/CampaignStore';
import mapStateToRoute from './plugins/Audiences/mapStateToRoute';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Client,
    Shortener,
    Campaign
  },
  plugins: [
    mapStateToRoute
  ],

  strict: false,
});
