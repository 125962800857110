import store from '@/store';

export default function setup(axios) {
  const axiosInstance = axios;
  axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
  axiosInstance.interceptors.request.use((config) => {
    const clientId = store.getters['Auth/clientId'];
    const localConfig = config;
    localConfig.params = {
      c: clientId,
      ...config.params,
    };
    return localConfig;
  }, error => Promise.reject(error));

  // INTERCEPTORS
  axiosInstance.interceptors.response
    .use(response => response, error => Promise.reject(error));
  Object.assign(axios, axiosInstance);
  return axiosInstance;
}
