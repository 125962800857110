import Vue from 'vue';

import stateToGetters from '@/store/helpers/stateToGetters';
import stateToCatchErrorAPI from '@/store/helpers/stateToCatchErrorAPI';
import stateToMutations from '@/store/helpers/stateToMutations';

const shortenerStore = {
  configs: null,
  campaigns: null,
  loadingCampaigns: false,
  selectedConfig: null,
  countCampaigns: 0,
  showHeader: false
};

const catchErrorAPI = stateToCatchErrorAPI(shortenerStore);

const actions = {
  resetCampaign({ commit }) {
    commit('SET_CAMPAIGNS', null);
    commit('SET_COUNT_CAMPAIGNS', 0);
  },
  setShowHeader({ commit }, value) {
    commit('SET_SHOW_HEADER', value);
  },
  setSelectedConfig({ commit }, config) {
    commit('SET_SELECTED_CONFIG', config);
  },
  getConfigsUser({ commit }, params) {
    return Vue.$shortener.get('/user/configs?sort=-_id', params)
      .then(({ data }) => {
        commit('SET_CONFIGS', data.data);
      })
      .catch(catchErrorAPI({ commit }));
  },
  getCampaigns({ commit }, params) {
    commit('SET_LOADING_CAMPAIGNS', true);
    return Vue.$shortener.get('/campaigns', { params })
      .then(({ data }) => {
        commit('SET_CAMPAIGNS', data.data);
        commit('SET_COUNT_CAMPAIGNS', data.count);
      })
      .catch(catchErrorAPI({ commit }))
      .finally(() => {
        commit('SET_LOADING_CAMPAIGNS', false);
      });
  },
  deleteCampaign(context, id) {
    return Vue.$shortener.delete(`/campaign/${id}`);
  },
  postCampaign(context, payload) {
    return Vue.$shortener.post('/campaigns', payload);
  }
};

export default {
  namespaced: true,
  state: shortenerStore,
  getters: stateToGetters(shortenerStore),
  actions,
  mutations: stateToMutations(shortenerStore),
};
