/* eslint-disable */
import axios from 'axios'
import setup from '@/plugins/setup-auth-api'

export default function plugin(Vue, pluginConfig) {
  if (plugin.installed) { return }
  Vue.$auth = setup(axios.create({
    baseURL: process.env.VUE_APP_AUTH_API,
  }))

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get () {
        return Vue.$auth
      }
    }
  })
}
