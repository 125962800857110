<template>
  <div>
    <el-dialog
      :title="`${creation ? 'Create' : 'Edit'} Shortened link`"
      :visible.sync="isOpen"
      width="50%"
      @closed="goToList()"
    >
      <div>
        <el-form
          ref="form"
          :model="model"
          :rules="rules"
          label-width="200px"
        >
          <el-form-item
            label="Description"
            prop="name"
          >
            <el-input
              v-model="model.name"
              placeholder="Purpose of your link"
            />
          </el-form-item>
          <el-form-item
            label="Destination URL"
            prop="url"
          >
            <el-input
              v-model="model.url"
              placeholder="Example: https://numberly.com/en/"
            />
          </el-form-item>
          <el-form-item
            label="Specific shortened URL"
            prop="short"
          >
            <el-input
              v-model="model.short"
              placeholder="Example: wOrd"
            />
          </el-form-item>
          <el-form-item
            label="Specific short TTL"
            prop="ttl"
          >
            <el-date-picker
              v-model="ttl"
              :clearable="false"
              type="date"
              placeholder="Inset date"
              style="width: 100%;"
              :editable="false"
              :picker-options="datePickerOptions"
              value-format="timestamp"
            />
          </el-form-item>
        </el-form>
        <span
          slot="
            footer"
          class="dialog-footer"
        >
          <div style="text-align: right;">
            <el-button @click="isOpen = false">
              Cancel
            </el-button>
            <el-button
              type="primary"
              @click="submitForm"
            >
              <div v-if="creation">
                Create
              </div>
              <div v-else>
                Save changes
              </div>
            </el-button>
          </div>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const modelName = 'entity';

export default {
  name: `${modelName}EditionPopup`,
  props: {
    creation: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      modelName,
      ttl: null,
      model: {
        name: '',
        url: '',
        short: '',
        ttl: null,
        config_id: null,
      },
      campaignFilter: {
        skip: 0,
        limit: 25,
        config_ids: null,
        sort: '-creation_date'
      },
      datePickerOptions: {
        disabledDate(date) {
          if (date > new Date().setFullYear(new Date().getFullYear() + 1)
          || date <= new Date()) { return true; }
          return false;
        }
      },
      isOpen: true,
      rules: {
        name: [
          { required: true, message: 'Please input description', trigger: 'blur' },
        ],
        url: [
          { required: true, message: 'Please input destination url', trigger: 'blur' }
        ],
      },
      client: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedConfig: 'Shortener/selectedConfig'
    }),
  },
  watch: {
    ttl: {
      handler(newValue) {
        const now = Date.now();
        if (newValue) {
          this.model.ttl = Math.round((newValue - now) / 1000);
        }
      }
    }
  },
  mounted() {
    if (this.selectedConfig && this.selectedConfig.ttl) {
      this.ttl = Date.now() + (this.selectedConfig.ttl * 1000);
    }
    this.client = this.$route.params.client;
    this.model.config_id = this.$route.params.configId;
    const {
      skip, limit, config_ids, sort
    } = this.$route.query;
    this.campaignFilter.skip = skip || 0;
    this.campaignFilter.limit = limit || 25;
    this.campaignFilter.config_ids = config_ids || null;
    this.campaignFilter.sort = sort || '-creation_date';
  },
  methods: {
    ...mapActions({
      postCampaign: 'Shortener/postCampaign',
      getCampaigns: 'Shortener/getCampaigns'
    }),
    goToList() {
      // rename entity by your model
      this.$router.push({ name: 'ShortenerClient', params: { client: this.client }, query: { ...this.$route.query } });
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveChanges();
        }
      });
    },
    async saveChanges() {
      if (this.creation) {
        // post entity
        try {
          if (this.model.short.trim() === '') delete this.model.short;
          await this.postCampaign(this.model);
          this.$message({
            type: 'success',
            message: 'Shortened link created successfully'
          });
          await this.getCampaigns({ ...this.campaignFilter });
          this.isOpen = false;
        } catch (err) {
          if (err.response && err.response.data && err.response.data.message) {
            this.$message.error(err.response.data.message);
          }
        }
      } else {
        // patch entity
        this.isOpen = false;
      }
    },
  }
};
</script>
