import Vue from 'vue';
import VueCookie from 'vue-cookie';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import Clipboard from 'v-clipboard';
import App from './App.vue';
import router from './router';
import store from './store';


import './scss/molecules/datalively.scss';
import './scss/element-variables.scss';
import './scss/layout.scss';

import Auth from './plugins/auth';
import Client from './plugins/client';
import Shortener from './plugins/shortener';
import Campaign from './plugins/campaign';

Vue.config.productionTip = false;


Vue.use(ElementUI, {
  locale,
});
Vue.use(Clipboard);

Vue.use(Auth);
Vue.use(Client);
Vue.use(Shortener);
Vue.use(Campaign);
Vue.use(VueCookie);

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

window.app = app;
