<template>
  <div
    id="app"
    class="container-ssr"
  >
    <Header v-if="showHeader" />
    <div class="main-ssr">
      <div class="main-ssr-container">
        <div
          id="body-ssr"
          class="body-ssr"
        >
          <el-row>
            <el-col>
              <RouterView />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  mounted() {
    const clientId = process.env.VUE_APP_CLIENT_ID || this.$route.query.c || this.$route.params.client || window.location.pathname.substring(1)
    this.$store.dispatch('Auth/set_client_id', clientId)
  },
  computed: {
    ...mapGetters({
      showHeader: 'Shortener/showHeader',
    })
  },
};
</script>

<style lang="scss" src="@/scss/molecules/datalively.scss"></style>
<style lang="scss" src="@/scss/molecules/chart-js-tooltip.scss"></style>
<style lang="scss" src="@/scss/molecules/block.scss"></style>
