<template>
  <ElRow class="audience">
    <ElCol
      :offset="1"
      :span="22"
    >
      <RouterView
        :key="$route.fullpath"
        name="content"
      />
    </ElCol>
    <ElCol
      v-if="showModal"
      :offset="2"
      :span="20"
    >
      <span ref="modal">
        <RouterView name="modal" />
      </span>
    </ElCol>
  </ElRow>
</template>
<script>
export default {
  name: 'MainView',
  data() {
    return {
      showModal: this.$route.meta.showModal,
    };
  },
  watch: {
    '$route.meta': {
      handler({ showModal }) {
        this.showModal = showModal;
      },
    },
  },
};
</script>
