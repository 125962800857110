import Vue from 'vue';
// import VueCookie from 'vue-cookie';

import stateToGetters from '@/store/helpers/stateToGetters';
import stateToCatchErrorAPI from '@/store/helpers/stateToCatchErrorAPI';
import stateToMutations from '@/store/helpers/stateToMutations';

// initial state
const campaignStore = {
  campaign: null,
};

const catchErrorAPI = stateToCatchErrorAPI(campaignStore);
// actions
const actions = {
  getUserCampaignById({ commit }, idc) {
    return Vue.$campaign.get(`/user/campaign/${idc}`)
      .then(({ data }) => {
        commit('SET_CAMPAIGN', data.data);
      }).catch(catchErrorAPI({ commit }));
  }
};

export default {
  namespaced: true,
  state: campaignStore,
  getters: stateToGetters(campaignStore),
  actions,
  mutations: stateToMutations(campaignStore),
};
